<template>
  <div>
    <hr class="yellow-rule" />
    <v-container>
      <v-row class="text-h5 text-sm-h4 my-1 my-sm-2 font-weight-bold text-center" justify="center">{{ $t("salary_submit_form_title") }}</v-row>
      <v-row class="text-h6 text-sm-h5 my-2 my-sm-8" justify="center">{{ $t("two_ways_to_submit") }}</v-row>
      <v-row cols="6" class="text-h6 font-weight-bold text-center py-0">
        <v-col>{{ $t("upload_documents") }}</v-col>
        <v-col>{{ $t("manually_input_data") }}</v-col>
      </v-row>
      <v-row class="text-center text-body-1 mb-2">
        <v-col cols="6" class="py-0">
          <v-row justify="center">
            <v-col cols="12">
              {{ $t("upload_documents_explanation") }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" class="py-0">
          <v-row justify="center">
            <v-col cols="12">
              {{ $t("manually_input_data_explanation") }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mb-2">
        <v-col cols="6" class="text-center py-0">
          <router-link
            :to="{ path: locale.base + '/verified-salaries' }"
            class="text-body-1 unverified-link mb-3 text-center"
            >{{ $t("read_about_verified_salaries") }}
          </router-link>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" class="pb-0">
          <v-row justify="center">
            <v-btn class="rounded-pill mb-3" justify="center" color="secondary" @click="displayFileUploadModal = true" dark>
              <v-icon v-if="!$vuetify.breakpoint.mobile" left dark large>mdi-plus</v-icon>
              {{ $t("upload_file") }}
            </v-btn>
          </v-row>
        </v-col>
        <v-col cols="6" class="pb-0">
          <v-row justify="center">
            <v-btn class="rounded-pill mb-3" color="secondary" :to="{ path: locale.base + '/submit-compensation' }" dark>
              <v-icon v-if="!$vuetify.breakpoint.mobile" left dark large>mdi-plus</v-icon>
              {{ $t("submit_basic_salary") }}
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card
              class="mx-auto pa-3"
              max-width="320"
              outlined
            >
            <v-row v-for="offer_letter in offer_letter_details" :key="offer_letter">
              <v-col class="text-center" cols="2">
                <v-icon
                  class="checked-icon"
                  small
                  >mdi-check</v-icon
                >
              </v-col>
              <v-col cols="10">
              {{ $t(offer_letter) }}
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card
              class="mx-auto pa-3"
              max-width="320"
              outlined
            >
            <v-row v-for="manually_input in manually_input_data_details" :key="manually_input">
              <v-col class="text-center" cols="2">
                <v-icon
                  class="checked-icon"
                  small
                  >mdi-check</v-icon
                >
              </v-col>
              <v-col cols="10">
              {{ $t(manually_input) }}
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="displayFileUploadModal"
      width="560"
    >
      <v-card class="text-center">
        <v-container>
          <v-row
            align="center"
            justify="center"
            class="mt-2"
            @drop="dropFile($event)"
            @dragenter.prevent
            @dragover.prevent
          >
            <i18n path="drag_drop">
              <a href="#" @click.stop.prevent="openFileSelection">
                {{ $t("upload") }}
              </a>
            </i18n>
            <input
              ref="uploader"
              type="file"
              multiple="multiple"
              style="display: none"
              accept=".pdf,.png,.jpg,.jpeg"
              @change="selectFiles($event.target.files)"
            >
            <v-card-text>
              <v-row justify="center" class="pb-8">
                <v-icon
                  class="checked-icon"
                  x-large
                  >mdi-cloud-upload-outline
                </v-icon>
              </v-row>
              {{ $t("file_upload_explanation") }}
              <v-row v-if="filesToUpload.length > 0" class="pt-3">
                <v-list-item
                  v-for="(file, index) in filesToUpload"
                  v-bind:key="index">
                  <v-row justify="center">
                    <v-chip close @click:close="removeFile(index)" style="white-space:normal;">{{ file.name }}</v-chip>
                  </v-row>
                </v-list-item>
                <v-row justify="end" class="mr-8 mt-6 mb-4">
                  <v-btn
                    color="secondary"
                    dark
                    @click="uploadDocuments(filesToUpload)"
                  >
                    {{ $t("submit_files") }}
                  </v-btn>
                </v-row>
              </v-row>
            </v-card-text>
            <v-alert
              v-if="uploadError"
              dense
              outlined
              type="error"
            >
            <div v-for="error in uploadError" :key="error">
              {{ $t("document") }} {{ error}}
            </div>
            </v-alert>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

  </div>
</template>
<i18n>
{
  "en": {
    "page_title": "Submit Compensation",
    "salary_submit_form_title": "Help make data more open, share your salary.",
    "two_ways_to_submit": "Two Ways to Submit",
    "upload_documents": "Upload an Offer Letter",
    "upload_documents_explanation": "Upload any offer letters or proof of compensation. This helps us make our data more accurate and helps us verify basic submissions.",
    "upload_file": "UPLOAD FILE",
    "read_about_verified_salaries": "Read more about Verified Salaries",
    "anonymous": "Anonymous",
    "optional_show": "Optionally show on site",
    "takes_15s": "Takes 15 seconds",
    "documents_deleted": "Documents are deleted as soon as they are confirmed",
    "awesome_human": "You are an awesome human being",
    "show_on_site": "Shown on site",
    "takes_45s": "Takes 45 seconds",
    "manually_input_data": "Manually Input Data",
    "manually_input_data_explanation": "Submit your data and we will manually review before it appears on the site.",
    "submit_basic_salary": "SUBMIT SALARY",
    "field_required": "This field is required",
    "add_normal_compensation": "Or add an unverified compensation",
    "show_label": "Show a verified label on this entry on the site.",
    "drag_drop": "Drag and drop files or {0}",
    "upload": "upload",
    "file_upload_explanation": "Please make sure the name of the company is visible. Please feel free to cross out any sensitive or identifying information.",
    "submit_files": "Submit",
    "document": "Document"
  },
  "ja": {
    "page_title": "年収を追加",
    "salary_submit_form_title": "データをオープンにしましょう、皆さんの給与情報をシェアしませんか？",
    "two_ways_to_submit": "2種類の提出方法",
    "upload_documents": "認証書類をアップロード",
    "upload_documents_explanation": "オファーレターや給与情報が証明できる書類(源泉徴収票、給与変更など)のアップロード。データが正確であることをより証明できます。",
    "upload_file": "ファイルを提出",
    "read_about_verified_salaries": "認証情報について読む",
    "anonymous": "匿名",
    "optional_show": "任意で本サイトに表示",
    "takes_15s": "15秒で登録",
    "documents_deleted": "書類は確認後即時に削除",
    "awesome_human": "あなたは素敵！",
    "show_on_site": "本サイトに表示",
    "takes_45s": "45秒で登録",
    "manually_input_data": "年収情報を入力",
    "manually_input_data_explanation": "年収情報の提出後、私たちがデータのレビューをし、サイトに開示します。",
    "submit_basic_salary": "給与を提出",
    "field_required": "この項目は入力必須です。",
    "add_normal_compensation": "または、認証せずに年収を追加",
    "file_upload_explanation": "会社名が表示されていることを確認してください。 機密情報や個人を特定できる情報は削除しても問題ありません。",
    "submit_files": "提出",
    "document": "書類"
  }
}
</i18n>

<script>
import api from "services/api";
import store from "store";

export default {
  components: {},
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used

      title: this.$t("page_title"),
      // all titles will be injected into this template
      titleTemplate: "%s | OpenSalary",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.$t("salary_submit_form_title")
        },
        {
          vmid: "og-title",
          property: "og:title",
          content: this.$t("page_title")
        },
        {
          vmid: "og-description",
          property: "og:description",
          content: this.$t("salary_submit_form_title")
        }
      ]
    };
  },
  data: () => ({
    formDisabled: false,
    offer_letter_details: [
      "anonymous",
      "optional_show",
      "takes_15s",
      "documents_deleted",
      "awesome_human"
    ],
    manually_input_data_details: [
      "anonymous",
      "show_on_site",
      "takes_45s",
      "awesome_human"
    ],
    displayFileUploadModal: false,
    uploadError: "",
    filesToUpload: []
  }),
  created() {
  },
  computed: {
    locale() {
      return store.getters.locale;
    }
  },
  methods: {
    uploadDocuments(files) {
      this.formDisabled = true;
      const formData = new FormData();
      files.forEach(file => {
        formData.append(
          "verification_documents[]",
          file
        );
      });
      api
        .createVerifiedSalaryDocumentsEntry(formData)
        .then(
          (resp) => {
            this.formDisabled = false;
            this.$router.push({ name: 'NewSubmitVerifiedCompensation', params: { verification_documents_id: resp.data.id } })
        },
         (error) => {
          this.uploadError = error.response.data.errors["verification_documents"]
         }
        )
    },
    isRequired(v) {
      return (!!v && !!v.toString().trim()) || this.$t("field_required");
    },
    dropFile(event) {
      event.preventDefault();
      const files = [];
      if (event.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        event.dataTransfer.items.forEach((item) => {
          const itemIsFile = item.kind === 'file';
          if (itemIsFile) {
            files.push(item.getAsFile());
          }
        });
      } else {
        // Use DataTransfer interface to access the file(s)
        event.dataTransfer.files.forEach((file) => {
          files.push(file.name);
        })
      }
      this.filesToUpload.push(...files);
    },
    selectFiles(files) {
      this.uploadError = "";
      this.filesToUpload.push(...files);
    },
    openFileSelection() {
      this.$refs.uploader.click();
    },
    removeFile(index) {
      this.uploadError = "";
      this.filesToUpload.splice(index, 1);
    }
  }
};
</script>

<style lang="scss" scoped>

.unverified-link {
  text-decoration: none;
}

.unverified-link:hover {
  text-decoration: underline;
}
